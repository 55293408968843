import styled from "styled-components";

const Button = styled.button`
  display: block;
  width: fit-content;
  padding: 0.4rem;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  border: 1px solid rgba(34, 34, 34, 1);
  color: color: rgba(34, 34, 34, 1);
  border-radius: 15px;
  background-color: transparent;
  cursor: pointer;
  transition: 0.3s opacity;
  :hover {
    opacity: 0.7;
  }
`;

export default Button;
