import React from "react";
import styled from "styled-components";

import Button from "../button";

const Footer = styled.footer`
  opacity: ${(props) => (props.hidden ? 0 : 1)};
  flex: 0;
  margin-top: auto; /* pin footer to the bottom */
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  transition: opacity 0.3s;
`;

const Contact = styled.div``;

const ContactLink = styled.a`
  display: block;
  margin-top: 0.5rem;
  text-decoration: none;
  :first-of-type {
    margin-top: 0;
  }
`;

const FooterComponent = ({ location }) => {
  return (
    <Footer hidden={location.pathname === "/contact"}>
      <Contact>
        <ContactLink href="mailto:brooke.is.nicholas@gmail.com">
          <Button>brooke.is.nicholas@gmail.com</Button>
        </ContactLink>
        <ContactLink
          href="https://www.instagram.com/broooohaha/"
          target="_blank"
          rel="noreferrer"
        >
          <Button>@broooohaha</Button>
        </ContactLink>
      </Contact>
    </Footer>
  );
};

export default FooterComponent;
