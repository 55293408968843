import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import sample from "lodash.sample";
import random from "lodash.random";

const Loader = styled.div`
  pointer-events: none;
  position: absolute;
  padding: 1rem;
  margin: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: ${(props) =>
    props.isLoaded ? "rgba(0,0,0,0)" : "rgba(255, 250, 251, 1)"};
  transition: 0.3s background-color;
  z-index: 2;
  overflow-x: hidden;
`;

const LoaderText = styled.p`
  opacity: ${(props) => (props.isLoaded ? 0 : 1)};
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1px;
  text-decoration: none;
  transition: 0.7s opacity;
`;

const FlowerElement = styled.p`
  font-size: 2rem;
  width: fit-content;
  position: absolute;
  top: ${(props) => `${props.top}px`};
  left: ${(props) => `${props.left}px`};
  @media (max-width: 768px) {
    font-size: 1.25rem;
  }
`;

const FlowerShadow = styled.span`
  opacity: 0.3;
  position: absolute;
  left: 3px;
  bottom: -3px;
  filter: blur(1px);
`;

const Flower = ({ loader }) => {
  const [hasRan, setHasRan] = useState(false);
  const [position, setPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (!hasRan) {
      setHasRan(true);
      setPosition({
        x: random(0, loader.current.offsetHeight),
        y: random(0, loader.current.offsetHeight),
      });
    }
  }, [hasRan, loader]);
  const [flower] = useState(sample(["✿", "❀", "❁"]));

  if (hasRan) {
    return (
      <FlowerElement top={position.y} left={position.x}>
        {flower}
        <FlowerShadow>{flower}</FlowerShadow>
      </FlowerElement>
    );
  } else {
    return null;
  }
};

const LoaderComponent = ({ numberOfFlowers }) => {
  // const [numberOfFlowers, setNumberOfFlowers] = useState(5)
  const [isLoaded, setIsLoaded] = useState(false);
  const loaderRef = useRef(null);
  // comment
  if (typeof window !== "undefined") {
    window.setTimeout(() => {
      setIsLoaded(true);
    }, 300);
  }

  const flowers = [];
  for (let i = 0; i < numberOfFlowers; i++) {
    flowers.push(<Flower key={i} loader={loaderRef} />);
  }

  return (
    <Loader isLoaded={isLoaded} ref={loaderRef}>
      <LoaderText isLoaded={isLoaded}>Brooke Nicholas</LoaderText>
      {flowers}
    </Loader>
  );
};

export default LoaderComponent;
