import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import random from "lodash.random";
import { useStaticQuery, graphql } from "gatsby";

import Transition from "../components/transition";
import Loader from "../components/loader";
import Footer from "../components/footer";
import Header from "../components/header";

import "focus-visible";
import "./layout.css";

const Layout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const Main = styled.main`
  position: relative;
  flex: 0;
  margin-bottom: 8rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const LayoutComponent = ({ children, location }) => {
  const [numberOfFlowers, setNumberOfFlowers] = useState(random(2, 7));
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <Layout>
      <Loader numberOfFlowers={numberOfFlowers} />
      <Header
        siteTitle={data.site.siteMetadata.title}
        handleClick={() => {
          setNumberOfFlowers(random(0, 10));
        }}
      />
      <Main>
        <Transition location={location}>{children}</Transition>
      </Main>
      <Footer location={location} />
    </Layout>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutComponent;
