import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

const Header = styled.header`
  flex: 0;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 4rem;
  @media (max-width: 768px) {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
  }
`;

const Navigation = styled.nav`
  > * {
    margin-right: 1rem;
    display: inline-block;
  }
  @media (max-width: 768px) {
    flex: 0 0 auto;
  }
`;

const NavigationLink = styled(Link)`
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.1px;
  text-decoration: none;
  @media (max-width: 768px) {
    &:last-of-type {
      padding-right: 1.5rem;
    }
  }
`;

const BuffLink = styled(NavigationLink)`
  color: rgba(255, 250, 251, 1);
`;

const DesktopFlower = styled.button`
  display: inline-block;
  width: fit-content;
  padding: 0;
  margin-right: 0;
  border: none;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
  @media (max-width: 768px) {
    display: none;
  }
`;

const MobileFlower = styled.button`
  display: none;
  border: none;
  background: transparent;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 1rem;
  @media (max-width: 768px) {
    display: block;
    position: absolute;
    right: 0;
    top: 1rem;
    width: 3rem;
    background: rgb(255, 250, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 250, 255, 0) 0%,
      rgba(255, 250, 255, 1) 50%
    );
    text-align: right;
  }
`;

const HeaderComponent = ({ handleClick }) => (
  <>
    <Header>
      <Navigation>
        <NavigationLink to="/">Brooke Nicholas</NavigationLink>
        <NavigationLink to="/blade-study">Blade Study</NavigationLink>
        <NavigationLink to="/exhibitions">Exhibitions</NavigationLink>
        {/* <NavigationLink to="/writing">Writing</NavigationLink> */}
        {/* <NavigationLink to="/experience">Experience</NavigationLink> */}
        <NavigationLink to="/contact">Contact</NavigationLink>
        <DesktopFlower onClick={handleClick}>✿</DesktopFlower>
        <BuffLink to="/buff">Buff</BuffLink>
      </Navigation>
    </Header>
    <MobileFlower onClick={handleClick}>✿</MobileFlower>
  </>
);

export default HeaderComponent;
